<template>
	<div class="">
		<div class="pa-10-pc bg-base">
			<div
				class="container tab justify-space-between"
			>
				<div class="pa-10-20-m bg-base-m">
					<ul>
						<li
							v-for="(tab, t_index) in items_tab"
							:key="'tab_' + t_index"

							class="inline-block mr-10"
						>
							<button
								class="font-weight-bold size-px-13"
								:class="tab.code == b_code ? 'color-primary' : ''"
								@click="toBbs(tab.code)"
							>{{ tab.name }}</button>
							<span
								v-if="items_tab.length > 0 && t_index < items_tab.length - 1"
							> |</span>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="container mt-50-pc">
			<div
			>
				<input
					v-model="item.b_title"
					placeholder="제목을 입력하세요"
					maxlength="50"
					class="input-underline"
				/>
			</div>

			<div
				class="mt-10"
			>
				<textarea
					v-model="item.b_contents"
					rows="10"
					placeholder="문의 내용을 입력하세요"
				></textarea>
			</div>

			<div
				class="mt-10"
			>
				<label class="mt-10 input-box full-width">
					<v-icon
						class="color-icon"
					>mdi mdi-image</v-icon>
					{{ file_name }}
					<input
						v-show="false"
						type="file"
						placeholder="대표 이미지"
						class="input-box"
						maxlength="250"
						@change="setFile"
						accept="image/*"
						multiple
					/>
				</label>
			</div>
		</div>
		<div
			class="pa-10 text-center mt-10"
		>
			<button
				class="btn btn-fill "
				@click="save"
			>{{ label_btn_save }}</button>
		</div>
	</div>
</template>

<script>

export default{
	name: 'BbsItem',
	components: { },
	props: ['user', 'codes']
	,data: function(){
		return {
			program: {
				name: ''
				, top: false
				, title: true
				, bottom: true
				, is_container: true
			}
			, items_tab: [
				{ name: '공지사항', code: 'b_notice'}
				, { name: '1:1문의', code: 'b_qna'}
				, { name: 'FAQ', code: 'b_faq'}
				, { name: '상품후기', code: 'b_after'}
			]
			,item: {
				b_code: this.$route.params.b_code
				,b_title: ''
				,b_contents: ''
				,b_file: []
				,m_name: this.user.member_name
			}
			,board_config: {

			}
			,b_code: this.$route.params.b_code
			,b_code_qna: 'b_qna'
		}
	}
	,computed: {
		label_contents: function(){
			if(this.b_code == this.b_code_qna){
				return '문의 내용'
			}else{
				return '내용'
			}
		}
		,label_btn_save: function(){
			if(this.b_code == this.b_code_qna){
				return '문의 하기'
			}else{
				return '등록'
			}
		}
		,file_name: function(){
			let name = '첨부파일'
			if(this.item.b_file.length > 0){
				for(let i = 0; i < this.item.b_file.length; i++){
					name += ' [' + this.item.b_file[0].name + ']'
				}
			}
			return name
		}
	}
	,methods: {
		getBoardConfig: async function(){
			try{
				const result = await this.$request.init({
					method: 'get'
					, url: 'board/getBoardconfig'
					, data: this.item
				})

				if(result.success){
					this.board_config = result.data
					this.program.name = this.board_config.b_name
					this.$bus.$emit('onLoad', this.program)
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(E){
				console.log(E)
				this.$bus.$emit('notify', { type: 'error', message: E })
			}
		}
		,save: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					, url: 'board/postBbs'
					, data: this.item
				})

				if(result.success){
					this.$bus.$emit('goBack')
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(E){
				console.log(E)
				this.$bus.$emit('notify', { type: 'error', message: E })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,setFile: function(e){
			this.item.b_file = e.target.files
		}
		,toBbs: function(b_code){
			this.$bus.$emit('to', {name: 'CustomerCenter', params: { b_code: b_code }})
		}
	}
	,created: function(){
		if(!this.user.member_id){
			alert('회원가입 후 이용가능합니다.')
			this.$bus.$emit('goBack')
		}
		this.getBoardConfig()
		this.$bus.$emit('onLoad', this.program)
	}
}

</script>

<style>
	label { display: inline-block}
</style>